import { serviceReducer as service } from '@mv-submodules/inplant-service-fe'
import { designerReducer as designer } from '@mv-submodules/inplant-designer-fe'
import { userReducer as user } from '@mv-submodules/inplant-user-fe'
import { production } from '@mv-submodules/inplant-production-fe-imel/redux/reducers'
import { graphI4EnvironmentData } from '@mv-submodules/inplant-environment-fe-imel/redux/reducers'
import { dateFilters } from '@mv-submodules/inplant-core-fe/ui/components/widgets/dateFiltersBase/reducers'
import { authReducer } from '@mv-submodules/inplant-core-fe/auth'
import { measureUnits } from './data'
import { plantMetrics } from './graphics'
import {
  graphGaugesData,
  graphPlanRunData,
  graphPlanRunOeeData,
  graphConsumptionData,
  graphQualityData,
  qualityDetailsData,
  availabilityDetailsData,
  graphAvailabilityData,
  availabilityDetailsAlarmsData,
  graphPerformanceData,
  graphTPRNCData,
} from '@mv-submodules/inplant-efficiency-fe-imel/redux/reducers'

import { planStatusOverTime } from '@mv-submodules/inplant-plantstatusovertime-fe-imel/redux/reducers'

export const config = {
  generic: {
    loginSuccessPath: '/dashboard',
    measureUnits,
    plantMetrics,
    updateNotification: (window as any).UPDATE_NOTIFICATION || process.env.REACT_APP_UPDATE_NOTIFICATION || null,
    version: (window as any).VERSION || process.env.REACT_APP_VERSION || null,
  },
  designer: {
    plantMemoryRoot: 'i4P.S7-1500.',
    plantPrefix: (window as any).PLANT_PREFIX || process.env.REACT_APP_PLANT_PREFIX || null,
    youtubeKey: (window as any).YOUTUBE_KEY || process.env.REACT_APP_YOUTUBE_KEY || null,
  },
  dashboard: {
    bestOfConfig: (window as any).BESTOF_CONFIG || process.env.REACT_APP_BESTOF_CONFIG || null,
  },
  production: {
    disabledActions:
      (window as any).PRODUCTION_DISABLED_ACTIONS || process.env.REACT_APP_PRODUCTION_DISABLED_ACTIONS || null,
    validJsonKeys:
      (window as any).PRODUCTION_VALID_JSON_KEYS || process.env.REACT_APP_PRODUCTION_VALID_JSON_KEYS || null,
    hiddenCounters:
      (window as any).PRODUCTION_HIDDEN_COUNTERS || process.env.REACT_APP_PRODUCTION_HIDDEN_COUNTERS || null,
    dataSourceFrames: (window as any).PRODUCTION_DATA_SOURCE_FRAMES || process.env.REACT_APP_PRODUCTION_DATA_SOURCE_FRAMES || false,
    dataSourceMapping: (window as any).PRODUCTION_DATA_SOURCE_MAPPING || process.env.REACT_APP_PRODUCTION_DATA_SOURCE_MAPPING || false,
  },
  environment: {
    disabledActions:
      (window as any).ENVIRONMENT_DISABLED_ACTIONS || process.env.REACT_APP_ENVIRONMENT_DISABLED_ACTIONS || null,
  },
  costs: {
    disabledActions: (window as any).COSTS_DISABLED_ACTIONS || process.env.REACT_APP_COSTS_DISABLED_ACTIONS || null,
  },
  widgets: {
    rt: (window as any).RTWIDGET_CONFIG || process.env.REACT_APP_RTWIDGET_CONFIG || null,
  },
  plantSelector: {
    isMultiPlant: (window as any).MULTI_PLANT || process.env.REACT_APP_MULTI_PLANT || false,
  },
}

// TODO check console.log
const requiredReducers: any[] = [config.designer.plantPrefix]
requiredReducers.forEach((r: any, index) => {
  if (!r) {
    throw Error(`${Object.keys(requiredReducers)[index]} var must be set!`)
  }
})
const optionalReducers: any[] = [config.designer.youtubeKey]
optionalReducers.forEach((r: any, index) => {
  if (!r) {
    console.log(`%c ${Object.keys(optionalReducers)[index]} var is not set!`, 'background: #fff; color: #f00;font-size: 14px') // tslint:disable-line
  }
})

export default {
  auth: authReducer,
  service,
  production,
  graphI4EnvironmentData,
  graphGaugesData,
  graphPlanRunData,
  graphPlanRunOeeData,
  graphConsumptionData,
  graphQualityData,
  qualityDetailsData,
  availabilityDetailsData,
  availabilityDetailsAlarmsData,
  graphAvailabilityData,
  graphPerformanceData,
  graphTPRNCData,
  user,
  designer,
  dateFilters,
  planStatusOverTime,
  config: () => config,
}
