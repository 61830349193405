export const measureUnits = {
  'planned loss': 's',
  'unplanned loss': 's',
  'Warm-up': 's',
  ActivePower: 'kWh',
  ActivePower_Both: 'kWh',
  ActivePower_Chiller: 'kWh',
  availability: '',
  Binder: 'kg',
  'Booth 1': 'kWh',
  'Booth 2': 'kWh',
  'Booth 1_m': 'm³',
  'Booth 2_m': 'm³',
  CarbonFootprint: '',
  CataOvenActivePower: 'kWh',
  CataOvenGas: 'm³',
  CompressedAir: 'm³',
  ComprimedAir: 'm³',
  ConPd01: 'l',
  Conveyor: 'kWh',
  Conveyor_m: 'm³',
  DemiWater: 'm³',
  DisPd01: 'l',
  'Dryer oven': 'Nm³',
  ExaustChaminsTemp: '°C',
  ExaustChaminsVol: 'm³',
  ExhaustChaminsTemp: '°C',
  ExhaustChaminsVol: 'm³',
  Gas: 'Nm³',
  Gas_Both: 'Nm³',
  Gas_Chiller: 'Nm³',
  oee: '',
  operating: 's',
  Paste: 'kg',
  performance: '',
  pieces: 'n',
  planned: 's',
  PostBurnerActivePower: 'kWh',
  PostBurnerGas: 'm³',
  PowderOvenActivePower: 'kWh',
  PowderOvenGas: 'm³',
  'Powder oven': 'Nm³',
  PreTreatment: 'kWh',
  quality: '',
  RawWater: 'm³',
  ready: 's',
  RefillWater: 'm³',
  run: 's',
  runTimeOperatingTime: '%',
  scrap: 'n',
  Sg1Pd01: 'l',
  SgsPd01: 'l',
  start: 's',
  Temperature_temperatureValue: '°C',
  ThermalEnergy: 'kWh',
  ThermalEnergyH: 'kWh',
  ThermalEnergyC: 'kWh',
  ThermalEnergy_Both: 'kWh',
  ThermalEnergy_Chiller: 'kWh',
}
