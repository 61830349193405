import { RouteProps } from '@mv-submodules/inplant-core-fe/types'
import dashboardRoutes from '../modules/Dashboard/routes'
import designerRoutes from '@mv-submodules/inplant-designer-fe/ui/routes'
import environmentRoutes from '@mv-submodules/inplant-environment-fe-imel/ui/routes'
import productionRoutes from '@mv-submodules/inplant-production-fe-imel/ui/routes'
import serviceRoutes from '@mv-submodules/inplant-service-fe/ui/routes'
import userRoutes from '@mv-submodules/inplant-user-fe/ui/routes'
import efficiencyRoutes from '@mv-submodules/inplant-efficiency-fe-imel/ui/routes'

const composeRoutes = ( moduleRoutes: RouteProps[] ): RouteProps[] => moduleRoutes.map(r => r)

environmentRoutes.iconOverride = '/i4environment.svg'
environmentRoutes.i18nkey = undefined
environmentRoutes.hiddenMobile = true

designerRoutes.iconOverride = '/i4plantdesigner.svg'
designerRoutes.i18nkey = undefined
designerRoutes.hiddenMobile = true

efficiencyRoutes.iconOverride = '/i4efficiency.svg'
efficiencyRoutes.i18nkey = undefined
efficiencyRoutes.hiddenMobile = true

productionRoutes.iconOverride = '/i4production.svg'
productionRoutes.i18nkey = undefined
productionRoutes.hiddenMobile = true

serviceRoutes.iconOverride = '/i4service.svg'
serviceRoutes.i18nkey = undefined
serviceRoutes.hiddenMobile = true

export const aclRoutes = ( sroutes: RouteProps[], forbiddenActions: string[], loginSuccessPath: string ) =>
  sroutes.filter(r => r)

export const aclRedirect = ( loginSuccessPath: string ) => loginSuccessPath

const routes: ( userRoles: string[] ) => RouteProps[] = () =>
  composeRoutes(
    [
      dashboardRoutes,
      environmentRoutes,
      // efficiencyRoutes,
      productionRoutes,
      serviceRoutes,
      userRoutes,
      designerRoutes,
    ]
  )

export default routes
