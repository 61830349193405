const en = {
  i4environment: {
    metrics: {
      'Booth 1_m': 'Booth 1',
      'Booth 2_m': 'Booth 2',
      Conveyor_m: 'Conveyor'
    }
  }
}

export default en
