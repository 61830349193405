export const plantMetrics = [
  {
    id: 0,
    label: 'media',
    type: 'section',
    data: [
      {
        id: 0,
        label: 'plant',
        measures: [
          { memory: 'plantActivePower', name: 'ActivePower', unit: 'kWh' },
          { memory: 'plantGas', name: 'Gas', unit: 'Nm³' },
          { memory: 'plantRawWater', name: 'RawWater', unit: 'm³' },
          { memory: 'plantComprimedAir', name: 'ComprimedAir', unit: 'm³' },
        ],
      },
      {
        id: 1,
        label: 'power',
        measures: [
          { memory: 'booth1ActivePower', name: 'Booth 1', unit: 'kWh'},
          { memory: 'booth2ActivePower', name: 'Booth 2', unit: 'kWh'},
          { memory: 'conveyorActivePower', name: 'Conveyor', unit: 'kWh'},
          { memory: 'pretreatmentPower', name: 'PreTreatment', unit: 'kWh'},
        ],
      },
      {
        id: 2,
        label: 'gas',
        measures: [
          { memory: 'dryerOvenGas', name: 'Dryer oven', unit: 'Nm³'},
          { memory: 'powderOvenGas', name: 'Powder oven', unit: 'Nm³'},
        ],
      },
      {
        id: 3,
        label: 'air',
        measures: [
          { memory: 'booth1Air', name: 'Booth 1_m'},
          { memory: 'booth2Air', name: 'Booth 2_m'},
          { memory: 'conveyorAir', name: 'Conveyor_m'},
        ],
      },
    ],
  }
];

export const mappedInfluxIDs = {
  pieces: 'pcs',
  qualityMeasure: 'pcs'
}

