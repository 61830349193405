const it = {
  i4environment: {
    metrics: {
      'Booth 1_m': 'Cabina 1',
      'Booth 2_m': 'Cabina 2',
      Conveyor_m: 'Trasportatore'
    }
  }
}

export default it
